import { defineStore } from "pinia";
import { Service } from "../types/service.type";

interface GlobalStore {
  services: Service[];
}

export const useGlobalStore = defineStore("globalStore", {
  state: (): GlobalStore => ({
    services: [],
  }),
  persist: {
    paths: ["services"],
  },
});
