<template>
  <div class="schedules">
    <div class="schedules__content">
      <div class="schedules__calendar">
        <Qalendar
          :events="events"
          :config="config"
          :is-loading="loading"
          @updated-period="periodUpdated"
          @interval-was-clicked="console.log"
          @datetime-was-clicked="console.log"
        >
          <!-- <template #eventDialog="props">
            <div v-if="props.eventDialogData && props.eventDialogData.title">
              <div :style="{ marginBottom: '8px' }">Edit event</div>

              <input
                class="flyout-input"
                type="text"
                :style="{ width: '90%', padding: '8px', marginBottom: '8px' }"
              />

              <button class="close-flyout" @click="props.closeEventDialog">
                Finished!
              </button>
            </div>
          </template> -->
        </Qalendar>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { Qalendar } from "qalendar";
import { configInterface } from "qalendar/dist/src/typings/config.interface";
import { getReservations } from "../services/api.service";
import { useCompanyStore } from "../stores/company.store";
import moment from "moment";

const config = ref<configInterface>({
  week: {
    startsOn: "monday" as any,
    nDays: 7,
    scrollToHour: 5,
  },
  month: {
    // Hide leading and trailing dates in the month view (defaults to true when not set)
    showTrailingAndLeadingDates: true,
  },
  eventDialog: {
    isCustom: true,
  },
  // Takes any valid locale that the browser understands. However, not all locales have been thorougly tested in Qalendar
  // If no locale is set, the preferred browser locale will be used
  style: {
    // When adding a custom font, please also set the fallback(s) yourself
    fontFamily: "'Nunito', sans-serif",
    colorSchemes: {
      meetings: {
        color: "#fff",
        backgroundColor: "#131313",
      },
      sports: {
        color: "#fff",
        backgroundColor: "#ff4081",
      },
    },
  },
  locale: "uk-UA",
  // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
  // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
  defaultMode: "day",
  // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
  isSilent: false,
  showCurrentTime: true, // Display a line indicating the current time
});
const events = ref<
  {
    title: string;
    with: string;
    time: { start: string; end: string };
    color?: string;
    colorScheme?: "meetings" | "sports";
    isEditable: boolean;
    id: string;
    description: string;
  }[]
>([]);

const loading = ref(false);
const startDate = ref(new Date().toUTCString());
const endDate = ref(new Date().toUTCString());

const companyStore = useCompanyStore();

onMounted(() => {
  loadEvents();
});

const loadEvents = async () => {
  loading.value = true;
  try {
    if (!companyStore.currentCompany) return;
    const reservations = await getReservations(
      companyStore.currentCompany.id.toString(),
      startDate.value,
      endDate.value
    );
    events.value = reservations.map((reservation) => ({
      title: reservation.users[0].name,
      with: reservation.services.map((service) => service.name).join(", "),
      time: {
        start: moment(reservation.start_date).format("YYYY-MM-DD HH:mm"),
        end: moment(reservation.end_date).format("YYYY-MM-DD HH:mm"),
      },
      colorScheme: "meetings",
      isEditable: false,
      id: `reservation.id`,
      description: `${reservation.services_sum} грн`,
    }));

    console.log(events.value);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const periodUpdated = (period: { start: Date; end: Date }) => {
  startDate.value = period.start.toISOString();
  endDate.value = period.end.toISOString();
  loadEvents();
};
</script>

<style lang="scss" scoped>
.schedules {
  height: calc(95vh - 126px);
  padding: 0 30px;

  &__content {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #fff;
  }

  &__calendar {
    height: 100%;
    overflow: scroll;
    padding: 25px;
  }
}
</style>
